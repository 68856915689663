export default function(el) {
  let lastPosition = -1;
  let elems = null;

  if (typeof el === 'string') {
    elems = Array.from(document.querySelectorAll(el));
  } else if (Array.isArray(el)) {
    elems = el;
  } else {
    elems = [el];
  }

  /* Bind update to listen to the resize event */
  window.addEventListener('resize', () => {
    updatePosition();
  });

  function updatePosition() {
    const windowHeight = window.innerHeight;
    elems.forEach((elem) => {
      const img = elem.getBoundingClientRect();
      const wrap = elem.parentNode.getBoundingClientRect();
      const wrapEntrance = wrap.top - windowHeight;
      const transitionMax = img.height - wrap.height;

      if (wrapEntrance < 0 && wrap.bottom > 0) {
        /* Parallax (bottom to top) */
        const factor = (img.height - wrap.height) / windowHeight; // The maximum without showing blank space, or hard code for consistency
        // const factor = 0.8; // This will make the parallax appear fixed, but only works for images above the fold
        const pos = Math.min(window.scrollY, (windowHeight - wrap.top)); // If at the top of the screen don't compensate for entry distances
        const transition = Math.floor((transitionMax * -1) + (pos * factor));
        elem.style.transform = `translate3d(-50%, ${transition}px, 0)`;

        /* Reverse Parallax (top to bottom) */
        // const transitionPercentage = Math.abs(wrap.top - windowHeight) // (wrap.height + windowHeight); // How far across the screen is the container baseline as a floating point
        // const baseline = transitionMax * -1; // place image on baseline of container (Bottom: 0; but as a delta)
        // const transition = baseline * transitionPercentage;
        // elem.style.transform = `translate3d(-50%, ${transition}px, 0)`;
      }
    });
  }

  function animate() {
    // If the offset position hasn't changed, skip this frame
    if (lastPosition !== window.pageYOffset) {
      updatePosition();
    }

    // Save the new offset position
    lastPosition = window.pageYOffset;
    window.requestAnimationFrame(animate);
  }

  return {
    animate
  }
}
