import './lib/foundation';
// import './lib/foundationStickyFooter';
import './lib/motion-ui';
import './includes/offcanvas';
import './includes/dropdowns';
import Layzr from 'layzr.js';
import Parallax from './modules/parallax';
import fixedHeader from './modules/fixedheader';

$(function () {
  /* =============================================
   Parallax Setup
   ============================================= */

  const parallax = new Parallax('.is-parallax').animate();

  /* -----  End of Parallax Setup  ------ */

  /* =============================================
   Sticky Header Desktop Transition
   ============================================= */

  const headerHeight = $('.top-bar-section').height();
  fixedHeader('.topbar--fixed').setHeight(headerHeight);

  /* -----  End of Sticky Header Desktop Transition  ------ */

  /* =============================================
   Lazy Load Images
   ============================================= */

  /* Initialize the lazy loader */
  /* https://github.com/callmecavs/layzr.js */
  const instance = Layzr({ threshold: 20 }); // Positive threshold makes images load sooner
  instance
    .update()         // track initial elements
    .handlers(true);  // bind scroll and resize handlers

  /* Bind the listeners to onLoad events */
  instance.on('src:before', (element) => {
    element.setAttribute('aria-busy', true);
    element.parentNode.setAttribute('aria-busy', true);
  });

  instance.on('src:after', (element) => {
    element.addEventListener('load', (e) => {
      e.target.setAttribute('aria-busy', false);
      e.target.parentNode.setAttribute('aria-busy', false);
      e.target.style.opacity = 1;
      window.dispatchEvent(new Event('resize')); // Make sure that we recalculate the parallax
    });
  });

  /* Now that the listeners are attached we can begin loading */
  instance.check();

  /* -----  End of Lazy Load Images  ------ */

  /* =============================================
   Foundation Init
   ============================================= */

  /* Initialize Foundation */
  $(document).foundation();

  /* -----  End of Foundation Init  ------ */
});



 // Fix Orbit loading mess
$(window).load(function () {
  $('.orbit').addClass('fadeIn');
});
