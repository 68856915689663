import Drop from 'tether-drop';

(function ($) {
  'use strict';
  $(document).ready(function () {


    // Social Dropdown
    // let socialDropdown = new Drop({
    //   target:  document.querySelector('#social-dropdown-toggle'),
    //   content: document.querySelector('#social-dropdown'),
    //   classes: 'drop-theme-basic',
    //   position: 'bottom left',
    //   openOn: 'click'
    // });

    // Search Dropdown
    // let searchDropdown = new Drop({
    //   target:  document.querySelector('#search-dropdown-toggle'),
    //   content: document.querySelector('#search-dropdown'),
    //   classes: 'drop-theme-basic',
    //   position: 'bottom right',
    //   openOn: 'click'
    // });

  });

  // $("html").click (function(){
  //   $("ul.sub-menu").slideUp(250);
  //   $("ul.menu li.has-dropdown").toggleClass("active-drop-state");
  // });

  /* Function to close all dropdowns in a menu, run before opening the selected one */
  function closeAllDropdowns(menu) {
    var $menu = $(menu);
    $menu.find('li.has-dropdown').each(function(i, el){
      $(el).removeClass('active-drop-state');
      $(el).find('> ul.sub-menu').slideUp(250);
    })
  }

  // Off Canvas 2nd tier menu slide
  var $menuItems = $('ul.menu > .has-dropdown > .nav-main-link');
  $menuItems.on('click', function (e) {
    e.preventDefault();
    var $item = $(this).parent('.has-dropdown');
    var $menu = $(this).parents('.menu');
    var $subMenu = $(this).siblings('.sub-menu');
    var isInPanel = $menu.parents('.Panel').length;

    if(! isInPanel){
      closeAllDropdowns($menu);
    }
    if ($subMenu.css('display') == 'none') {
      $item.addClass("active-drop-state");
      $subMenu.slideDown(250);
    } else {
      $subMenu.slideUp(250);
    }
  });

})(jQuery);
