export default function (el) {
  let topbar = document.querySelector(el);
  let headerHeight = 0;
  let lastScrollY = 0;

  animate();

  function animate() {
    if (lastScrollY !== window.scrollY) {
      lastScrollY = window.scrollY;
      updateHeader()
    }
    requestAnimationFrame(animate);
  }

  function updateHeader() {
    if (!topbar) return; // Early return if we can't match an element

    const heightToKeep = topbar.offsetHeight - headerHeight;
    const currentScrollY = lastScrollY;
    if (currentScrollY < heightToKeep) {
      topbar.style.transform = `translateY(${currentScrollY * -1}px)`;
      topbar.classList.add('is-attached');
      topbar.classList.remove('is-detached');
    } else {
      topbar.style.transform = `translateY(${heightToKeep * -1}px)`;
      topbar.classList.add('is-detached');
      topbar.classList.remove('is-attached');
    }
  }

  /* ==========  Public Methods  ========== */

  function setHeight(height) {
    headerHeight = height;
    return this;
  }

  function setElement(el) {
    topbar = document.querySelector(el);
    return this;
  }

  return {
    setHeight,
    setElement,
  };
}
