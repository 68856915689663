import Emoter from 'emoter';

(function ($) {
  $(document).ready(function () {
    const panels = Emoter();
    const overlay = $('.Site-overlay');
    const $toggleButtonLeft = $('#PanelToggleLeft');
    const $toggleButtonRight = $('#PanelToggleRight');
    const $panelLeft = $('#PanelLeft');
    const $panelRight = $('#PanelRight');
    const $site = $('.Site');
    const $header = $('.MobileHeader');

    /* Events */
    overlay.on('click', e => panels.state('position', 'closed'));
    $toggleButtonRight.on('click', () => {
      const newState = panels.state('position') === 'closed' ? 'right' : 'closed';
      panels.state('position', newState);
    });
    $toggleButtonLeft.on('click', () => {
      const newState = panels.state('position') === 'closed' ? 'left' : 'closed';
      panels.state('position', newState);
    });
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27 && panels.state('position') != 'closed') {
        panels.state('position', 'closed');
      }
    });

    /* State Actions */
    const transitions = {
      closed() {
        $panelLeft.attr('data-panelstate', 'closed');
        $panelRight.attr('data-panelstate', 'closed');
        $site.attr('data-translationstate', null);
        $header.attr('data-translationstate', null);
      },
      left() {
        $panelLeft.attr('data-panelstate', 'open');
        $panelRight.attr('data-panelstate', 'closed');
        $site.attr('data-translationstate', 'right');
        $header.attr('data-translationstate', 'right');
      },
      right() {
        $panelLeft.attr('data-panelstate', 'closed');
        $panelRight.attr('data-panelstate', 'open');
        $site.attr('data-translationstate', 'left');
        $header.attr('data-translationstate', 'left');
      },
    };

    /* Listeners */
    panels.on('position', () => {
      const pos = panels.state('position');
      //console.log(`PanelStatus is: ${pos}`);
      if (transitions.hasOwnProperty(pos)){
        transitions[pos]();
      };
    });

    /* Init */
    panels.load = function () {
      this.state('position', 'closed');
    };

    panels.load();
  });
})(jQuery);

